.overflow-hero-blob {
	margin-top: -120px;
	padding-top: 180px;
	padding-top: 40vh;
	margin-bottom: 30vh;
	padding-bottom: 0;

	// overflow schmutz
	.info-cell {
		margin-bottom: -10vh;
	}
	@include mq(m) {
		padding-top: 180px;
		margin-bottom: calc(var(--wp--style--block-gap) * 2);

		.info-cell {
			margin-bottom: 40px;
		}
	}

	@include mq(l) {
		padding-top: 260px;
	}


	.info-cell {
		position: relative;
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		width: auto;
		aspect-ratio: 1.25;
		margin-top: auto;
		justify-self: center;
		align-self: flex-end;

		@include mq(m) {
			width: 50%;
			justify-self: flex-end;
		}
		.info-wrap {
			position: relative;
			padding: 30px 10px;
			z-index: 1;
			max-width: 100%;
			width: max-content;
		}

		.blob-wrap {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 0;
			transform: scale(1.4);

			@include mq(m) {
				transform: scale(1.1);
			}

			fancy-blob {
				position: absolute;

				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 0;

				canvas {
					width: 100%;
					height: 100%;
					color: $bright-green;
					fill: $bright-green;
					overflow: visible;
				}

				&:nth-child(2) {
					opacity: .6;
				}

				&:nth-child(3) {
					opacity: .3;
				}

			}
		}
	}

	.swoop-wrap {
		position: absolute;
		transform: translateY(50%);
		left: -10%;
		bottom: -12vh;
		width: 80%;
		color: $quinary-color;

		svg {
			transform: rotateY(180deg);
		}

		@include mq(m) {
			bottom: 0;
			width: 75%;
			left: unset;
		}
	}


	&[data-is-preview] {
		margin-top: 0;
		padding-top: 30px;
		padding-bottom: 20px;
		background-position: center;
		background-size: cover;
		.blob-wrap {
			transform: scale(1) !important;
		}

		.info-wrap {
			padding: 20px;
		}

		.swoop-wrap {
			bottom: -8%;
			left: 0;
			width: 60%;
			color: $quinary-color;
		}
	}
}
