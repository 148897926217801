/*
[is-preview] {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
*/



.contain-background {
	background-size: contain;
}


.full-height-cols {
	.wp-block-column {
		display: flex;
		flex-flow: column nowrap;
		align-self: stretch;
		margin-bottom: 2rem;
	}

	.ghostkit-col-content {
		display: flex;
		flex-flow: column nowrap;
		align-self: stretch;
		margin-bottom: 2rem;
	}
}


.z-index-1 {
	z-index: 1;
}
