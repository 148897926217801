.nestable-blob-block {
	display: flex;
	position: relative;
	margin: 1rem;

	fancy-blob {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;

		svg {
			width: 100%;
			height: 100%;
			color: $tertiary-color;
			overflow: visible;
		}
	}

	.content-wrap {
		position: relative;
		z-index: 1;
		padding: 30px;
	}

	&.alignright {
		margin-left: auto;
		float: unset;
	}

	&.smaller-blob {
		svg {
			padding: 10%;
		}
	}

	&.smaller-image {
		.image-wrap {
			padding: 10px;
		}
	}
}
