.page-404 {
	padding-top: 175px;
	padding-bottom: 100px;

	.c-grid {
		--grid-col-lg: 1fr 1.5fr;
		row-gap: 150px;
		max-width: 500px;
		margin: 0 auto;

		@include mq(l) {
			max-width: 100%;
		}
	}

	p {
		margin: 1.5em 0;
		font-size: var(--fluid-h6);
	}

	h2 {
		margin-bottom: 0;
	}

	.content-cell {
		.blob-wrap {
			position: relative;
			max-width: 100%;
			width: 400px;
			z-index: 1;

			fancy-blob {
				position: absolute;
				top: -40%;
				left: -20%;
				right: -20%;
				bottom: -40%;
				width: 140%;
				height: 180%;
				color: $bright-green;
				z-index: -1;

				svg {
					width: 100%;
					height: 100%;
				}

				&:nth-child(2) {
					opacity: 0.5;
				}
			}
		}
	}

	.chips-cell {
		position: relative;
		z-index: -1;

		.chip-bag {
			position: relative;
			width: 140%;
			max-width: unset;
			left: -30%;
			z-index: -1;

		}
	}
}
