[data-bm-button] {
	position: relative;
	cursor: pointer;
	background: unset;
	border: unset;
}

[data-better-modal] {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	visibility: hidden;
	z-index: 1000;


	&[data-bm-is-open] {
		visibility: visible;
	}

	[data-bm-overlay] {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index:  1;
	}

	[data-bm-card] {
		max-width: 100vw;
		max-height: 100vh;
		overflow: auto;
		z-index: 2;
	}

	// Positioning engine for the modal
	&[data-bm-position="top"] {
		justify-content: center;
		align-items: flex-start;
	}

	&[data-bm-position="left"] {
		justify-content: flex-start;
		align-items: center;
	}

	&[data-bm-position="right"] {
		justify-content: flex-end;
		align-items: center;
	}
	&[data-bm-position="bottom"] {
		justify-content: center;
		align-items: flex-end;
	}
	&[data-bm-position="center"] {
		justify-content: center;
		align-items: center;
	}
}

body[data-bm-offcanvas] {
	position: relative;
}
// TODO Remove

body {
	margin: 0;

	&:not([data-s-loaded]) {
		* {
			transition: none !important;
		}
	}
}

@import "better-modal-theme";
