/**
 * Link styling
 * Links can get pretty fancy so the setting partials do not get involved
 **/

a {
	color: $secondary-color;
	transition: all .3s ease;
	font-weight: 600;

	&:hover, &:focus {
		color: $secondary-color-dark;
	}

	&:active {
		color: darken($primary-color-dark, 10%);
	}
}
