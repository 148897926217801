$white: #ffffff;
$black: #000000;
$gray: #a2a2a2;
$light-gray: #EDF0EF;

$primary-color: #292563;
$secondary-color: #3BB455;
$tertiary-color: #38C68B;
$quaternary-color: #6DC49C;
$quinary-color: #D2F0E2;

$bright-green: #03C44D;
