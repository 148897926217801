[data-bm-button] {
	display: flex;
	flex-flow: column nowrap;
	text-align: center;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: var(--bm-button-padding-tb) var(--bm-button-padding-lr);
	background: var(--bm-button-background-color);
	border: var(--bm-button-border);

	&[data-bm-close] {
		.close-icon {
			width: var(--bm-toggle-line-width);
		}
	}
}
.toggle-line {
	display: flex;
	width: var(--bm-toggle-line-width);
	height: var(--bm-toggle-line-height);
	margin-bottom: var(--bm-toggle-line-gap);
	border-radius: var(--bm-toggle-line-border-radius);
	background: var(--bm-toggle-line-color);
}


[data-better-modal] {
	[data-bm-card] {
	}
	@at-root {
		[data-bm-animation] {
			transition-duration: var(--bm-transition-duration);
			transition-timing-function: var(--bm-transition-duration);
		}

		[data-bm-animation~="slide-top"] {
			transform: translateY(-100%);
			&[data-bm-active] {
				transform: translateY(0%);
			}
		}

		[data-bm-animation~="slide-left"] {
			transform: translate(-100%);
			&[data-bm-active] {
				transform: translate(0%);
			}
		}
		[data-bm-animation~="slide-right"] {
			transform: translate(100%);

			&[data-bm-active] {
				transform: translate(0%);
			}
		}

		[data-bm-animation~="slide-bottom"] {
			transform: translateY(100%);
			&[data-bm-active] {
				transform: translateY(0%);
			}
		}

		[data-bm-animation~="fade"] {
			opacity: 0;

			&[data-bm-active] {
				opacity: 1;
			}
		}
	}

	[data-bm-overlay] {
		transition: background var(--bm-transition-duration) var(--bm-transition-timing-function);
		background: transparent;

		&[data-bm-active] {
			background: var(--bm-overlay-color);
		}
	}

	& &[data-bm-animation="bottom"] {
		transform-origin: top center;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 100%);

		&[data-bm-is-open] {
			transform: translate(-50%, 0%);
		}
	}

	&[data-bm-animation="center"] {
		transform-origin: center center;
		width: 80%;
		height: 80%;

		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;

		&[data-bm-is-open] {
			transform: translate(-50%, -50%);
			opacity: 1;
		}
	}

	&[data-bm-position="center"] {
	}
}
