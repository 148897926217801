/**
 * Fluid properties, makes life so much easier responsiveness wise
 * BIG WARNING THIS ONLY OPERATES IN Pixel units
 **/

// The min value to stop at (usually somewhere in the 320px - 400px zone)
$fluid-min-width: 400px;

// Max width should be the global width;
$fluid-max-width: $global-width;

/**
 * Fluid Properties
 * Allows properties to be linearly fluid between two viewport values.
 * This is useful for a variety of typography and responsive sizing needs
 *
 * @group Settings Mixins
 * @param {property} $property - A css property
 * @param {pixel value} $min-size - The smallest size this value will shrink to on small viewports
 * @param {pixel value} $max-size - The largest size this value will stretch to on large viewports
 * @param {pixel value} $min-vw - OPTIONAL: can set the min viewport for custom styling needs
 * @param {pixel value} $max-vw - OPTIONAL: can set the max viewport for custom styling needs
 **/
@mixin fluid-prop($property, $min-size, $max-size, $min-vw: $fluid-min-width, $max-vw: $fluid-max-width) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-size);
	$u4: unit($max-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			#{$property}: $min-size;
			@media (min-width: $min-vw) {
				#{$property}: calc(
					#{$min-size} + #{strip-unit($max-size - $min-size)} *
						((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
				);
			}
			@media (min-width: $max-vw) {
				#{$property}: $max-size;
			}
		}
	}
}

@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}
