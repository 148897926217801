/**
 * Super section :D
 * A section that contains content
 * The section/inner-wrap is set to flex column for easy placement of children elements 🙌
 **/

$s-padding-tb: $padding-c-tb;
$s-padding-lr: $padding;
$s-width: $global-width;

// Width for sections that should have inner-wrap constrained
$s-constrain-width: 1200px;

.s- {
	&section {
		display: flex;
		flex-flow: column nowrap;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		width: 100%;
		max-width: 100%;
	}

	&inner-wrap {
		display: flex;
		flex-flow: column nowrap;
		padding: $s-padding-tb $s-padding-lr;
		width: $s-width;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		justify-self: stretch;
		align-self: stretch;
		overflow: hidden;
		z-index: 1;
		flex-grow: 1;
	}

	// Utility modifiers these should be placed on the section element
	&center {
		> .super-inner-wrap,
		.s-inner-wrap {
			align-items: center;
			justify-content: center;
		}
	}

	&flex-end {
		> .super-inner-wrap,
		.s-inner-wrap {
			justify-content: flex-end;
		}
	}

	&constrain {
		.super-inner-wrap,
		.s-inner-wrap {
			width: $s-constrain-width;
		}
	}
}
