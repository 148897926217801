.callout-section {
	display: flex;
	flex-flow: column nowrap;
	background: $primary-color;
	min-height: 400px;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		color: $white;
	}


	.swoop {
		svg {
			color: $secondary-color;
			fill: $secondary-color;
		}
	}

	.overflow-grid {
		display: flex;
		flex-flow: row nowrap;
		flex-grow: 1;
		height: 100%;
		width: 100%;

		.content-cell {
			position: relative;
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: flex-end;
			padding: 75px 20px;
			min-width: 50%;
			max-width: 65%;
			flex-shrink: 1;
			flex-grow: 1;
			background-color: $primary-color;

			@include mq(m) {
				flex-basis: 50%;
			}
			&:after {
				--triangle-width: 20%;
				content: '';
				// css triangle
				position: absolute;
				top: 0;
				right: calc(-1 * var(--triangle-width) + 2px);
				width: var(--triangle-width);
				height: 100%;
				background: $primary-color;
				clip-path: polygon(0 0, 0% 100%, 100% 0);
				z-index: 1;
			}

			.content-wrap {
				max-width: 450px;
			}
		}

		.image-cell {
			min-width: 20%;
			max-width: 50%;
			flex-shrink: 1;
			flex-grow: 1;
			position: relative;

			@include mq(m) {
				flex-basis: 50%;
			}

			.swoop {
				display: block;
				margin: 0;
				position: absolute;
				z-index: 1;
				top: 0;
				width: 200%;
				min-width: 600px;
				right: -50%;
				transform: translate(50%, -50%);

				@include mq(m) {
					right: 15%;
					width: 100%;
					transform: translate(0, -50%);

				}
			}

			.image-wrap {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;


				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center center;
				}
			}

		}
	}
}
