/**
 * Functional better menu styles
 * Better off not touching this stuff
 **/

@import "./vars";

// Base Better menu styles for any menu
[data-better-menu] {
	display: flex;

	// debug
	//* { border: 1px solid black;}
	// Some menus have a wrapping div

	// Reset better menu elements
	ul,
	li,
	a,
	div {
		margin: unset;
		padding: unset;
	}

	// Removes list style and sets flex
	ul {
		display: flex;
		list-style: none;

		li {
			position: relative;
			display: flex;
			flex-flow: column nowrap;
		}
		a {
			width: auto;
			align-self: var(--bm-submenu-item-alignment);
		}

		// Hide any inner menus set flex-flow column
		ul {
			visibility: hidden;
			flex-flow: column nowrap;
			height: 0;
		}

		// Dropdown anchor
		[data-bm-dropdown-anchor] {
			position: relative;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;


			[data-bm-dropdown-arrow] {
				pointer-events: all;
				padding: var(--bm-dropdown-arrow-padding);
				transition: var(--bm-a-transition);
				transform: scaleY(1);
				svg {
					height: var(--bm-dropdown-arrow-height);
					width: var(--bm-dropdown-arrow-width);
					color: var(--bm-dropdown-arrow-color);

					// prevent all pointer events on inner paths to make life alil easier
					pointer-events: none;
				}
			}

			// Rotate that arrow!
			&[data-bm-active] {
				[data-bm-dropdown-arrow] {
					transform: scaleY(-1);
				}
			}
		}
	}
}

[data-better-menu="vertical"] {
	ul {
		flex-flow: column nowrap;

		li {
			ul {
				height: 0;

				&[data-bm-active] {
					visibility: visible;
				}
			}
		}
	}
}

[data-better-menu="horizontal"] {
	ul {
		flex-flow: row nowrap;
		align-items: center;
		li {
			ul {
				position: absolute;
				top: 100%;
				right: 0;
				align-items: var(--bm-submenu-item-alignment);

				&[data-bm-active] {
					// Fancy animations are borderline impossible with absolute auto height containers. Stick with transform
					visibility: visible;
				}
			}
		}
	}
}


@import "better-menu-theme";
