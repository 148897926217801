/**
 * Generic footer layout engine 📏
 **/
$s-footer-width: $global-width;
$s-footer-bg-color: $gray;
$s-footer-padding-t: 1rem;
$s-footer-padding-b: 1rem;
$s-footer-padding-lr: 1rem;

$s-footer-cell-padding-lr: 0.6rem;
$s-footer-cell-padding-tb: 0.6rem;
// Important setting here aligns the cells on small!
$s-footer-cell-width-sm: 230px;

$s-footer-menu-width: auto;
$s-footer-menu-item-padding-t: 0.6rem; // menu item padding top
$s-footer-menu-item-padding-b: 0.6rem; // menu item padding bottom
$s-footer-menu-item-padding-lr: 1rem;
$s-footer-menu-item-font-weight: bold;

.s-footer {
	display: flex;
	flex-flow: column nowrap;

	background-color: $s-footer-bg-color;
	.s-footer-inner-wrap {
		display: flex;
		flex-flow: column nowrap;
		max-width: $s-footer-width;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		padding: $s-footer-padding-t $s-footer-padding-lr $s-footer-padding-b;
	}

	.s-footer-grid {
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
		justify-content: center;
		width: $s-footer-cell-width-sm;
		margin-left: auto;
		margin-right: auto;

		@include breakpoint(medium) {
			width: 100%;
			justify-content: space-between;
		}
	}

	.s-footer-cell {
		padding: $s-footer-cell-padding-tb $s-footer-cell-padding-lr;
		display: block;
		max-width: 100%;
		width: $s-footer-cell-width-sm;

		@include breakpoint(medium) {
			width: auto;
		}
	}
	.s-footer-logo-container {
		.s-footer-logo {
			width: 100%;
			max-width: 100%;
			max-height: 60px;
			height: 50px;
		}

		@include breakpoint(medium) {
			width: auto;
		}
	}

	// Ambigious selector to help make this work anywhere!
	.s-footer-menu {
		width: $s-footer-cell-width-sm;

		@include breakpoint(medium) {
			width: $s-footer-menu-width;
		}

		ul {
			display: flex;
			flex-flow: column nowrap;

			list-style: none;
			width: 100%;

			@include breakpoint(medium) {
				margin-left: -$s-footer-menu-item-padding-lr;
			}

			li {
				width: auto;
				font-weight: $s-footer-menu-item-font-weight;
				text-align: center;

				a {
					padding-top: $s-footer-menu-item-padding-t;
					padding-bottom: $s-footer-menu-item-padding-b;
				}
			}

			@include breakpoint(medium) {
				flex-flow: row nowrap;
			}
		}
	}

	.s-footer-copyright {
		text-align: center;
	}
}

.s-footer-copyright-row {
	background-color: $light-gray;
	color: white;

	a {
		color: white;
		transition: all 0.3s ease;

		&:hover,
		&:active,
		&:focus {
			color: $quaternary-color;
		}
	}

	i {
		padding: 0.3rem;
	}
	.inner-wrap {
		display: flex;
		flex-flow: column nowrap;
		max-width: $s-footer-width;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		padding: 20px $s-footer-padding-lr 20px;
		justify-content: center;
		align-items: center;

		@include mq(s) {
			flex-flow: row nowrap;
			justify-content: space-between;
		}
	}

	.right-cell {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;

		@include mq(m) {
			flex-flow: row nowrap;
		}
		.foundation-link {
			display: flex;
			margin: 1rem;
			padding-top: 1rem;
			margin-top: 0;
			text-decoration: underline;
			font-weight: 500;

			@include mq(s) {
				padding-top: unset;
				margin: unset;
				margin-right: 1rem;
			}
		}

		.footer-socials {
			display: flex;
			justify-content: center;
			margin-top: 0.5rem;

			@include mq(m) {
				margin-top: 0;
			}
		}
	}
}

.s-footer-menu {
	a {
		color: $quaternary-color;

		font-weight: 500;
		&:hover,
		&:active,
		&:focus {
			filter: brightness(110%);
		}
	}

	.is-active {
		a {
			background: transparent;
			font-weight: 700;
			color: $secondary-color;
		}
	}
}

.s-footer-grid {
	@include mq(l, down) {
		justify-content: center;

		.menu {
			justify-content: center;
		}
	}

	.menu-item {
		flex-shrink: 0; // jesus flex can't ever just let things grow...
	}
}
