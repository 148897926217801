.regular-hero-blob {
	padding-top: 0;
	padding-bottom: 0;
	margin-top: 1rem;
	margin-bottom: calc(var(--wp--style--block-gap) * 2);
	z-index: 1;
	.info-cell {
		position: relative;
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		padding: 20px;
		width: fit-content;
		align-self: flex-start;
		margin-top: -1rem;
		margin-bottom: -1rem;
		max-width: 80%;
		justify-self: center;


		@include mq(m) {
			justify-self: flex-start;
			padding: 30px;
			max-width: 500px;
		}
		.info-wrap {
			position: relative;
			display: flex;
			flex-flow: column;
			justify-content: center;
			flex-grow: 1;
			width: fit-content;
			margin: 30px 0;
			padding: 10px;
			z-index: 1;

			@include mq(m) {
				margin: 60px 0px;
			}
		}

		.blob-wrap {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 0;

			fancy-blob {
				position: absolute;

				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 0;
				transform: scale(1.2);

				canvas {
					width: 100%;
					height: 100%;
					color: $bright-green;
					fill: $bright-green;
				}

				&:nth-child(2) {
					opacity: .6;
				}

				&:nth-child(3) {
					opacity: .3;
				}

			}
		}
	}

	.swoop-wrap {
		position: absolute;
		bottom: -10%;
		left: 0;
		width: 60%;
		color: $quinary-color;
	}


	&[data-is-preview] {
		margin-top: 20px;
		background-position: center;
		.blob-wrap {
			top: 0;
			left: 0;
			width: 105%;
			height: 105%;
		}

		.info-wrap {
			padding: 20px;
		}

		.swoop-wrap {
			bottom: -8%;
			left: 0;
			width: 60%;
			color: $quinary-color;
		}
	}

	&.overflow-header {
		@include mq(m, down) {
			z-index: 1;
			background-size: 120%;
			background-position: top center;
			//margin-bottom: -10vh;

			.info-cell {
				margin-top: 40%;
				justify-self: center;
			}
		}

		@include mq(s, down) {
			background-size: 130%;
		}
	}
}
