.circle-section-block {
	display: flex;
	flex-direction: column;
	margin-top: 30px;

	&.overflow {
		padding-top: 0;
		padding-bottom: 0;
		margin-top: 4rem;

		@include mq(l) {
			margin-top: 2rem;
			margin-bottom: 2rem;
		}
		.circle-image-wrap {
			margin-top: -3rem;

			aspect-ratio: 1 / 1;
			@include mq(l) {
				margin-top: -2rem;
				margin-bottom: -1rem;

				max-width: 100%;
				flex-grow: 1;
			}
		}

		.content-cell {
			padding-bottom: 2rem;
			max-width: 600px;
			justify-self: center;


			@include mq(l) {
				max-width: 100%;
				padding-top: 4rem;
				padding-bottom: 3rem;
			}
		}
	}
	.c-grid {
		--grid-col-lg: 3fr 4fr;
		--grid-gap: 60px;
		max-width: $global-width;
		width: 100%;
		margin: 0 auto;
		padding-left: 20px;
		padding-right: 20px;
	}



	.circle-image-wrap {
		position: relative;
		display: flex;
		aspect-ratio: 1;
		max-width: 300px;
		border-radius: 1000px;
		border: 4px solid $white;
		overflow: hidden;
		align-self: center;
		width: 100%;
		flex-grow: 1;

		@include mq(m) {
			max-width: 400px;
		}

		@include mq(l) {
			max-width: 100%;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}


}
