#hero {
  display: flex;
  background: $primary-color;
}


#home {

	.solution-section {

		.wp-block-columns {
			justify-content: center;
		}

		.wp-block-column {
			height: auto;
		}

		.image-blob-block {
			aspect-ratio: 1.1;
			align-items: center;
		}
	}
}
