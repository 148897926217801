.styled-form-block {
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	justify-self: center;

	.form-wrap {
		display: flex;
		flex-flow: column nowrap;
		padding: 2rem 1rem;
		background-color: $primary-color;
		width: 100%;
		z-index: 1;

		@include mq(m){
			padding-top: 8rem;
			padding: 6rem 2rem;
		}

		.fluentform {
			max-width: calc(800px - 40px);
			width: 100%;
			margin: 0 auto;
		}
	}

	.header-wrap {
		position: relative;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: flex-end;
		width: 100%;

		.blobs-wrap {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			align-self: flex-end;
			margin: 0 auto;
			z-index: 1;
			padding: 2rem;
			padding-bottom: 1rem;

			@include mq(m) {
				margin: unset;
			}
			.header-text {
				color: $primary-color;
				margin-bottom: 0;
				margin-right: auto;
				z-index: 1;
			}
			fancy-blob {
				position: absolute;

				width: 125%;
				height: 250%;
				max-width: unset;
				//transform: translate(75%, 75%);
				z-index: 0;
				color: $bright-green;
				canvas {
					width: 100%;
					height: 100%;
				}
				&:nth-child(1) {
					opacity: .9;
					padding: 1rem;
				}
				&:nth-child(2) {
					opacity: .5;
				}
				&:nth-child(3) {
					opacity: .2;
				}

				@include mq(m) {
					height: 180%;
				}

				@include mq(l) {
					height: 250%;
				}
			}
		}

		.chip-wrap {
			position: absolute;
			display: none;
			width: 250px;
			margin-left: -2rem;
			margin-right: 1rem;
			right: 0;
			bottom: -6rem;
			z-index: 2;
			@include mq(m) {
				display: block;
			}

			@include mq(l) {
				bottom: -10rem;
				width: 300px;
			}
		}

		@include mq(l) {

		}
	}
}


.fluentform {
	.ff-el-input--label {
		label {
			font-size: var(--fluid-body);
			font-weight: 500 !important;
			color: white;
		}
	}

	.ff-el-input--content {
		font-weight: 500 !important;

		input, textarea {
			font-size: var(--fluid-body);
			font-family: $header-font-family;
			border-radius: 0;
		}
	}


	.ff-el-group {
		button {
			@extend .fancy-btn;
			@extend .btn-green;
			background-color: $bright-green !important;
		}
	}
}
