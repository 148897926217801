.s-section, .wp-block-group {
	--section-width: #{$global-width};
	--padding-horizontal: 20px;
	--padding-vertical: 50px;
	padding: var(--padding-vertical) var(--padding-horizontal);
	display: grid;
	grid-template-columns: 1fr minmax(0, var(--section-width)) 1fr;
	width: 100%;
	/**
	* Forces all first level children
	* to align to the center column that has minmax to create a gutter
	**/
	> * {
		grid-column: 2 / span 1;
	}

	.full-width {
		grid-column: span 3;
	}

	.alignwide {
		grid-column: span 3;
		max-width: var(--global-width-wide);
		justify-self: center;
		width: 100%;
	}

	.alignfull {
		position: relative;
		grid-column: span 3;
		width: calc(100% + (var(--padding-horizontal) * 4));
	}

}

.gutenberg-content {
	//display: flex;
	//flex-flow: column nowrap;
	//align-items: center;
	padding: var(--section-padding);
	gap: var(--wp--style--block-gap);

	// Overwrite section padding
	.s-section {
		padding-left: 0;
		padding-right: 0;
	}

	> * {
		max-width: 100%;
		width: 1200px
	}
}
