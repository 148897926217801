.featured-companies-block {
	padding-top: 20px;
	padding-bottom: 20px;

	--swiper-navigation-color: #{$quaternary-color};
	--swiper-navigation-size: 30px;
	.slider-arrow-wrap {
		position: relative;
		padding-left: 20px;
		padding-right: 20px;
		margin-top: 40px;

		@include mq(m) {
			padding-left: 30px;
			padding-right: 30px;
		}

		.swiper-button-prev {
			left: -15px;
		}

		.swiper-button-next {
			right: -15px;
		}
	}
	.swiper-button-prev, .swiper-button-next {
		font-weight: bold;
	}

	.swiper-slide {
		width: auto;
	}
	.featured-company-logo {
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			max-height: 60px;
		}
	}



	&[data-editor] {
		a {
			pointer-events: none;
		}
	}
}
