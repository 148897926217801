.header-nav {
	position: relative;
	@extend .s-section;
	padding: 10px 20px !important;
	z-index: 100;
	.header-row {
		display: flex;
		width: 100%;
	}
	.header-cell {
		display: flex;
	}

	.nav-cell {
		align-items: center;
		justify-content: flex-end;
		flex-grow: 1;
	}
	.logo-container {
		flex-shrink: 0;
	}
	.logo {
		width: 180px;
		flex-shrink: 0;
	}

	a {
		font-weight: 700;
		text-transform: uppercase;
		font-size: var(--fluid-body-xs);
	}

	.soc-grid {
		display: none;

		@include mq(l) {
			display: flex;
		}
	}

}

// Better menu mods

#desktop-nav {
	display: none;
	align-self: center;
	> div {
		width: 100%;
		display: flex;
		align-self: center;

		> ul {
			justify-content: space-evenly;
			flex-grow: 1;
		}
	}



	@include mq(l) {
		display: flex;
	}

	a {
		margin: 0 20px;
		font-size: var(--fluid-body-sm);
	}

	.callout-link {

		a {
			@extend .fancy-btn;
			@extend .btn-green;
			padding-left: 1em;
			padding-right: 1em;
		}

	}
}

#mobile-nav-button {
	display: flex;

	@include mq(l) {
		display: none;
	}
}

#navigation-modal {



	.contact-line {
		display: flex;
		font-weight: 700;
		flex-flow: row nowrap;
		align-items: center;
		align-self: flex-start;
		text-decoration: none;
		font-size: var(--fluid-h6);
		margin-top: 1rem;

		figure {
			margin-top: unset;
			margin-left: unset;
			margin-bottom: unset;
			height: 1.3em;
			width: auto;
			margin-right: .5em;

			svg {
				height: 100%;
			}
		}

		color: $quaternary-color;

		&:hover, &:active, &:focus {
			color: $bright-green;
		}


	}

	a {
		color: white;

		&:hover,
		&:focus {
			color: $secondary-color;
		}

		&:active {
			color: $secondary-color-dark;
		}
	}
	[data-bm-card] {
		width: 100%;
		background: $primary-color;

		.bm-card-inner {
			padding: 2em;
		}

		[data-bm-button] {
			color: white;
			margin-left: auto;
			padding: 1rem;
			font-weight: 500;
			cursor: pointer;

			svg {
				padding: 0.4rem 0.2rem;
			}
		}
	}

	.nav-donate-btn {
		display: flex;
		align-items: center;
		margin-top: 2rem;

		svg {
			height: 1em;
			margin-right: 0.4rem;
		}
	}
}

// make sub menu items abit less bold

#mobile-nav {
	align-items: center;
	align-self: center;
	justify-content: center;
	margin: 0 auto;

	li {
		margin-bottom: .8em;

		a {
			font-size: var(--fluid-h6);
			margin: 1rem 0;

		}

		&:last-child {
			a {
				margin-top: 40px;
				@extend .fancy-btn, .btn-green;
			}
		}

		> ul {
			padding-top: 0;

			a {

			}

		}
	}

	.callout-link {
		order: -1;
		background: darken($primary-color-dark, 5%);
	}
}


.menu-row {
	padding-top: 0.8em;
	padding-bottom: 0.8em;
}

.callout-link-wrap {
	display: flex;
	@include mq(l) {
		display: none;
	}

	a {
		padding: 1em 1.2em;
		background: $primary-color;
		color: white;
		text-decoration: none;
		margin-right: .5em;

		&:hover, &:focus {
			background: $primary-color-dark;
		}

		&:active {
			background: darken($primary-color-dark, 5%);
		}
	}
}

#navigation-modal {
	[data-bm-card] {
		height: 100%;

		@include mq(m) {
			width: 350px;
		}
	}

	[data-bm-close] {

	}

	.card-header {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: 20px;
	}
	[data-bm-card] {
		display: flex;
		flex-flow: column nowrap;
	}
	.bm-card-inner {
		display: flex;
		flex-flow: column nowrap;
		padding-top: 100px;
		flex-grow: 1;
	}

	li {
		align-self: center;
	}

	a {
		font-size: var(--fluid-body);
		text-align: center;
	}

	.logo-container {
		margin-top: 2rem;

		img {
			width: 240px;
			max-width: 100%;
		}

	}

	.soc-grid {
		margin-top: auto;
		margin-left: auto;
		margin-right: auto;
		--icon-color: white;
		display: flex;
		align-self: flex-end;
		align-items: center;
		align-content: center;
		justify-content: center;
	}
}

.bm-card-inner {
	max-width: 100%;
	margin: 0 auto;
	width: 350px;
}
