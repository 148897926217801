.image-blob-block {
	display: flex;
	position: relative;
	margin: unset;
	width: fit-content;
	max-width: 100%;
	aspect-ratio: 1 / 1;

	fancy-blob {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		padding-bottom: 100%;

		svg, canvas {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			color: $tertiary-color;
			overflow: visible;
			padding: 0%;
		}
	}

	.image-wrap {
		position: relative;
		z-index: 1;
		padding: 30px;
		margin: auto auto;
	}

	&.alignright {
		margin-left: auto;
		float: unset;
	}

	&.smaller-blob {
		canvas {
			padding: 10%;
		}
	}

	&.smaller-image {
		.image-wrap {
			padding: 10%;
		}
	}
}
