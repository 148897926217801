/**
 * Buttons
 **/

.fancy-btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding: .7em 2.4em;
	line-height: 1.2;
	background: white;
	font-weight: 500;
	box-shadow: inset 0 0 0 1px $primary-color;
	border: unset;
	border-radius: 200px;
	color: $primary-color;
	text-decoration: none;
	transition: all .3s ease;
	overflow: hidden;
	z-index: 1;

	&:before {
		content: '';
		height: calc(100% + 2px);
		width: 0;
		position: absolute;
		background: $primary-color-light;
		border-radius: 1000px;
		z-index: -1;
		top: -2px;
		left: -2px;
		transition: all .3s ease;
	}

	// hover states
	&:hover, &:focus, &:active, &.selected {
		color: $white;
		&:before {
			width: calc(100% + 2px);
		}
	}

	// active state - click
	&:active, &.selected {
		color: white;
		&:before {
			background: $primary-color-dark;
		}
	}

	&.btn-solid {
		background: $primary-color;
		color: white;
		font-weight: bold;
		&:hover, &:focus, &:active {
			background: darken($primary-color-dark, 5%);
		}
	}

	&.btn-narrow {
		padding: 0.8em 1.6em;
	}

	&.btn-dark {
		background: $primary-color;
		color: $white;
		font-weight: bold;
		box-shadow: inset 0 0 0 1px $primary-color;

		&:before {
			background: lighten($primary-color, 10%);
		}

		&:hover, &:focus, &:active {
			background: darken($primary-color, 5%);
		}

		&:active {
			&:before {
				background: lighten($primary-color, 5%);
			}
		}
	}

	&.btn-square {
		border-radius: 0;

		&:before {
			border-radius: 0;
		}
	}

	&.btn-white {
		background: $white;
		outline: unset;
		color: $primary-color;
		box-shadow: unset;
		font-weight: bold;
		&:hover, &:focus, &:active {
			&:before {
				background: $light-gray;
			}
		}

		&:active {
			&:before {
				background: darken($light-gray, 5%);
			}
		}
	}

	&.btn-green {
		background: $bright-green;
		outline: unset;
		color: $white;
		box-shadow: unset;
		font-weight: bold;

		&:before {
			background: $bright-green;
		}

		&:hover, &:focus, &:active {
			&:before {
				background: lighten($bright-green, 5%);
			}
		}

		&:active {
			&:before {
				background: darken($bright-green, 3%);
			}
		}
	}

	.icon {
		margin-right: .5em;
	}
}


.wp-block-buttons, .wp-block-button {
	display: flex;
	flex-flow: row wrap;
	gap: 10px;
	margin-top: .5rem;
	margin-bottom: 1rem;
	.wp-block-button__link {

		font-size: var(--fluid-body-sm);
		@extend .fancy-btn;
		@extend .btn-solid;

		&.has-white-background-color {
			background: $white;
			outline: unset;
			color: $primary-color;
			box-shadow: unset;
			font-weight: bold;
			&:hover, &:focus, &:active {
				&:before {
					background: $light-gray;
				}
			}

			&:active {
				&:before {
					background: darken($light-gray, 5%);
				}
			}
		}

		&.has-bright-green-background-color {
			background: $bright-green;
			outline: unset;
			color: $white;
			box-shadow: unset;
			font-weight: bold;

			&:before {
				background: $bright-green;
			}

			&:hover, &:focus, &:active {
				&:before {
					background: lighten($bright-green, 5%);
				}
			}

			&:active {
				&:before {
					background: darken($bright-green, 3%);
				}
			}
		}
	}
}
