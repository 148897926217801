$lighten-amount: 15%;
$primary-color-light: scale-color($primary-color, $lightness: $lighten-amount);
$secondary-color-light: scale-color($secondary-color, $lightness: $lighten-amount);
$tertiary-color-light: scale-color($tertiary-color, $lightness: $lighten-amount);
$quaternary-color-light: scale-color($quaternary-color, $lightness: $lighten-amount);

$darken-amount: 15%;
$primary-color-dark: scale-color($primary-color, $lightness: -$darken-amount);
$secondary-color-dark: scale-color($secondary-color, $lightness: -$darken-amount);
$tertiary-color-dark: scale-color($tertiary-color, $lightness: -$darken-amount);
$quaternary-color-dark: scale-color($quaternary-color, $lightness: -$darken-amount);

@mixin color-vars() {
	:root {
		--black: #{$black};
		--gray: #{$gray};
		--white: #{$white};

		--primary-color: #{$primary-color};
		--secondary-color: #{$secondary-color};
		--tertiary-color: #{$tertiary-color};
		--quaternary-color: #{$quaternary-color};

		--primary-color-light: #{$primary-color-light};
		--secondary-color-light: #{$secondary-color-light};
		--tertiary-color-light: #{$tertiary-color-light};
		--quaternary-color-light: #{$quaternary-color-light};

		--primary-color-dark: #{$primary-color-dark};
		--secondary-color-dark: #{$secondary-color-dark};
		--tertiary-color-dark: #{$tertiary-color-dark};
		--quaternary-color-dark: #{$quaternary-color-dark};
	}
}
